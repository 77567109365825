/*  Switch -> The container

    *------------------------*
    |                        |
    |                        |
    *------------------------*
*/
/*  State -> Every Item inside the container 

    *------*-----------------*
    | Item |                 |
    |      |                 |
    *------*-----------------*
*/
/*   Overlay -> The layer that covers every item when item is active.

    *------*-----------------*
    | On   |                 |
    |      |                 |
    *------*-----------------*
*/
.abg-switch {
  width: 100%;
  min-height: 45px;
  box-sizing: border-box;
  margin: 10px; }

.abg-switch__container {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 45px;
  border: 1px solid white;
  border-radius: 100px;
  z-index: 1; }

/*  Switch -> The container

    *------------------------*
    |                        |
    |                        |
    *------------------------*
*/
/*  State -> Every Item inside the container 

    *------*-----------------*
    | Item |                 |
    |      |                 |
    *------*-----------------*
*/
/*   Overlay -> The layer that covers every item when item is active.

    *------*-----------------*
    | On   |                 |
    |      |                 |
    *------*-----------------*
*/
.abg-switch__state {
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: inherit;
  cursor: pointer;
  width: 100%;
  height: 100%;
  padding: 15px;
  font-weight: bold;
  background: transparent;
  overflow: visible;
  color: inherit;
  font: inherit;
  border: none; }
  .abg-switch__state:not(.switch__state--disable):hover {
    color: #e6e6e6; }
  .abg-switch__state input {
    display: none; }

.abg-switch__state--on {
  z-index: 2;
  color: #e66549 !important;
  font-weight: bold; }

/*  Switch -> The container

    *------------------------*
    |                        |
    |                        |
    *------------------------*
*/
/*  State -> Every Item inside the container 

    *------*-----------------*
    | Item |                 |
    |      |                 |
    *------*-----------------*
*/
/*   Overlay -> The layer that covers every item when item is active.

    *------*-----------------*
    | On   |                 |
    |      |                 |
    *------*-----------------*
*/
.abg-switch__overlay {
  position: absolute;
  height: 100%;
  border-radius: 100px;
  background-color: rgba(249, 249, 249, 0.5);
  box-shadow: 0 0 6px 1px #e66549;
  border: 1px solid transparent;
  box-sizing: border-box;
  transition: all 0.4s ease; }

